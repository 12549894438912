import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Project } from '../../data/projects';
import { Link } from 'react-router-dom';

interface ProjectCardProps {
  project: Project;
}

export default function ProjectCard(props: ProjectCardProps) {

  return (
    <Card sx={{ maxWidth: 660 }} className="card">
      <CardActionArea to={`/${props.project.slug}`} component={Link} onClick={() => window.scrollTo(0,0)}>
        <CardMedia
          component="img"
          height="140"
          image={require(`../../assets/${props.project.slug}/thumbnail.${props.project.thumbnailExt}`)}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className='title'>
            {props.project.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="subtitle">
            {props.project.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
