import { Box, Typography } from '@mui/material';
import * as React from 'react';

import './style.scss';

const images = ['cesare-1', 'cesare-2'];

export default function PageNotFound() {

  const imageName = images[Math.floor(Math.random() * images.length)]
  const imgSrc = require(`../../assets/${imageName}.png`);

  return (
    <Box className="section not-found">
      <Typography variant="h2" className='heading'>
            The page you're looking for does not exist, but this picture does.
      </Typography>
      <img src={imgSrc} className="image"/>
    </Box>
  );
}
