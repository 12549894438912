import React from 'react';
import { Container, Typography } from '@mui/material';

import './style.scss';

export default function Footer() {

  return (
    <Container className='footer'>
      <Typography className='footer__text'>
        {'© 2022. ALL RIGHTS RESERVED TO ADILAH BECK.'}
      </Typography>
    </Container>
  );
}
