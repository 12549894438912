import * as React from 'react';
import ProjectInfoSection from '../ProjectInfoSection';
import { Button, ButtonGroup, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import schemaDesignerNew from '../../../../assets/aws-amplify-studio/schema-designer/demo.gif';
import schemaDesignerOld from '../../../../assets/aws-amplify-studio/schema-designer/old-experience.png';
import deployGif from '../../../../assets/aws-amplify-studio/save-and-deploy/data-model.gif';
import deploySuccess from '../../../../assets/aws-amplify-studio/save-and-deploy/success.png';
import webDemo from '../../../../assets/aws-amplify-studio/cli-login/web-demo.gif';
import cliDemo from '../../../../assets/aws-amplify-studio/cli-login/terminal-demo.gif';

import './style.scss';

enum SubProject {
    SCHEMA = 'schema-designer',
    DEPLOY = 'save-and-deploy',
    LOGIN = 'cli-login'
}

export default function Content() {

  const [params, setParams] = useSearchParams();
  let initParam = params.get('id');
  if (!initParam || !Object.values<string>(SubProject).includes(initParam)) {
    initParam = SubProject.DEPLOY;
  }

  const [selected, setSelected] = React.useState<SubProject>(initParam as SubProject);

  function isActive(p: SubProject) {
    return p === selected;
  }

  const onSelectButton: React.MouseEventHandler = (e) => {
    const select = (e.target as HTMLElement).id as SubProject;
    setSelected(select)
    setParams({ id: select });
  }

  const onSelectMenuItem = (e: SelectChangeEvent) => {
    setSelected(e.target.value as SubProject)
    setParams({ id: e.target.value });
  }

  function getButtons(collapsed: boolean) {
    return [
      { selection: SubProject.DEPLOY, text: 'Save and Deploy' },
      { selection: SubProject.SCHEMA, text: 'Schema Designer' },
      { selection: SubProject.LOGIN, text: 'Login' }
    ].map(({ selection, text }) => {
      if (collapsed) {
        return (
          <MenuItem className='dropdown-item' value={selection}>{text}</MenuItem>
        )
      }
      return (
        <Button
          id={selection}
          className={isActive(selection) ? 'active' : ''}
          onClick={onSelectButton}
        >
          {text}
        </Button>
      )
    });
  }

  return (
    <div className='section'>
      <ProjectInfoSection
        title={'Feature Work'}
      >
        <ButtonGroup
          className="button-group center-text"
          variant="outlined"
          aria-label="outlined button group"
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          {getButtons(false)}
        </ButtonGroup>

        <Select
          className='dropdown'
          value={selected}
          onChange={onSelectMenuItem}
          variant="outlined"
          sx={{ display: { xs: 'block', sm: 'none' } }}
        >
          {getButtons(true)}
        </Select>
      </ProjectInfoSection>

      {selected === SubProject.SCHEMA && (
        <ProjectInfoSection
          subtitle={'GraphQL Schema Viewer'}
          content={'This project expands Amplify Studio\'s data model designer to give users the option to view their models as a raw GraphQL schema.'}
        >
          <img src={schemaDesignerOld} className='oversized-img'/>
          <Typography className='caption'>
                    Old data model designer experience
          </Typography>
          <img src={schemaDesignerNew} className='oversized-img'/>
          <Typography className='caption'>
                    New data model designer experience allows users to switch between different views and includes guidance on how to edit/copy their raw GraphQL schema.
          </Typography>
        </ProjectInfoSection>
      )}

      {selected === SubProject.DEPLOY && (
        <ProjectInfoSection
          subtitle={'Save and Deploy Experience'}
          content={'This project enhances the user experience when deploying app backends with new animations, deployment information and next step guidance.'}
        >
          <img src={deployGif} className='oversized-img'/>
          <Typography className='caption'>
                    Users can track their deployment progress with logs and visualizations.
          </Typography>
          <img src={deploySuccess} className='oversized-img'/>
          <Typography className='caption'>
                    After their deployment finishes, users are presented with guidance on what to do next.
          </Typography>
        </ProjectInfoSection>
      )}

      {selected === SubProject.LOGIN && (
        <ProjectInfoSection
          subtitle={'CLI Copy & Paste Login'}
          content={'This project introduces an alternative method for users to log into the Amplify CLI using Amplify Studio, particularly for users using browsers (such as Safari and Brave) that don\'t allow communication with the Amplify CLI\'s local http server that listens for authentication tokens from the browser, or users running the Amplify CLI remotely.'}
        >
          <img src={webDemo} className='oversized-img'/>
          <Typography className='caption'>
            When the browser fails to connect to the Amplify CLI, users are prompted to copy their login tokens.
          </Typography>
          <img src={cliDemo} className='oversized-img'/>
          <Typography className='caption'>
             Pasting the copied tokens logs the user in.
          </Typography>
        </ProjectInfoSection>
      )}

      <Typography variant="body2" className="content center-text">
        Fun fact: This website was built with <a href='https://aws.amazon.com/amplify/' target="_blank" rel="noopener noreferrer">AWS Amplify</a>!
      </Typography>
    </div>
  );
}
