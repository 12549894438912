import * as React from 'react';
import ProjectInfoSection from '../ProjectInfoSection';
import { Typography } from '@mui/material';
import map from '../../../../assets/umich-ccs-locator/map.png';
import list from '../../../../assets/umich-ccs-locator/list.png';

export default function Content() {

  return (
    <div className='section'>
      <ProjectInfoSection
        title={'User Story'}
        content={'Students, faculty and staff at the University of Michigan need a way to quickly locate computing sites that fit their needs so that they can easily plan their schedules and commutes.'}
      />
      <ProjectInfoSection
        title={'Research'}
        content={'I worked with UX design fellows to conduct research on what users want to see in this new tool. We conducted intercept surveys at select locations to gather information about what features users look for in a computing site and what factors are important to them. The common trends were printing, copying, workstations, location on campus and operating hours.'}
      />
      <ProjectInfoSection
        title={'Ideation'}
        content={'The product we decided to build was a webpage consisting of a map view that shows users all the computing sites on campus and a filter tool that allows users to filter the results based on what they\'re looking for.'}
      />
      <ProjectInfoSection
        title={'Implementation'}
      >
        <img src={map} className='oversized-img'/>
        <Typography className='caption'>
                The map view was implemented using Google Maps Javascript API.
        </Typography>
        <img src={list} className='oversized-img'/>
        <Typography className='caption'>
                In considering accessibility, a screen reader-friendly list view was added.
        </Typography>
      </ProjectInfoSection>
    </div>
  );
}
