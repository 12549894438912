import * as React from 'react';
import ProjectInfoSection from '../ProjectInfoSection';

export default function Content() {

  return (
    <div className='section'>
      <ProjectInfoSection>
        <iframe
          className='video'
          src="https://www.youtube.com/embed/ns67g8J2AV8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </ProjectInfoSection>
    </div>
  );
}
