import * as React from 'react';
import ProjectCard from './ProjectCard';
import { Project, projects } from '../../data/projects';
import './style.scss';

export default function ProjectCollection() {

  const cards: JSX.Element[] = projects.map(
    (p: Project): JSX.Element => {
      return <ProjectCard
        project={p}
      />
    }
  );

  return (
    <div className="projects">
      {cards}
    </div>
  );
}
