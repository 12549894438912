import * as React from 'react';
import ProjectInfoSection from '../ProjectInfoSection';
import { Typography } from '@mui/material';
import existingPage from '../../../../assets/umich-ccs-landing-page/existing-page.png';
import mockup from '../../../../assets/umich-ccs-landing-page/thumbnail.png';

export default function Content() {

  return (
    <div className='section'>
      <ProjectInfoSection
        title={'User Story'}
        content={'When students, faculty and staff log into a campus computer, they need a simple landing page so that they can easily find the information and links they are looking for.'}
      />
      <ProjectInfoSection
        title={'Research'}
        content={'I worked with UX design fellows to conduct research on what users want to see on the new landing page. We conducted intercept surveys and user testing at select locations to discover how students used the existing landing page, what they liked about it and what improvements they wanted to see. We found that many users did not actually use the page regularly as they found it text-heavy and lacked visual appeal. Users either closed the page immediately or resorted to simply googling what they needed, and this major pain point drove our ideation process.'}
      >
        <img src={existingPage} />
        <Typography className='caption'>
                Existing landing page
        </Typography>
      </ProjectInfoSection>
      <ProjectInfoSection
        title={'Ideation & Design'}
        content={'Simplification and visuals were the main considerations during the ideation process. Non-essential information, such as the computing sites description, was discarded. To engage users to interact with the page, the links were replaced with clickable icons that best represented the destination and organized into cards. Some additional information that users stated would be helpful, such as site hours and features, were added. The announcements section was slimmed down to a dismissible green dialog box that appeared at the top of the page.'}
      >
        <img src={mockup} />
        <Typography className='caption'>
                Mockup of new landing page
        </Typography>
      </ProjectInfoSection>
    </div>
  );
}
