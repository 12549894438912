import * as React from 'react';
import ProjectInfoSection from '../ProjectInfoSection';
import design from '../../../../assets/mri-simulator/design.png';
import prototype from '../../../../assets/mri-simulator/prototype.jpeg';
import finalProduct from '../../../../assets/mri-simulator/final-product.png';
import demo from '../../../../assets/mri-simulator/demo.gif';

export default function Content() {

  return (
    <div className='section'>
      <ProjectInfoSection>
        <iframe
          className='video'
          src="https://www.youtube.com/embed/xmyK5hzOuhY"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </ProjectInfoSection>
      <ProjectInfoSection
        title={'User Story'}
        content={'An MRI (Magnetic Resonance Imaging) is a procedure that can produce fear and anxiety in many pediatric patients due to the blaring noises, tight spaces and unfamiliar environment. Hospital staff typically resort to sedation for patients with increased levels of anxiety before the procedure. This has become a source of frustration for families and hospital staff who feel that sedation is unnecessary. To improve the MRI procedure experience for these pediatric patients and drive down sedation rates, patients need a tool that can help familiarize them with the process of an MRI scan in a fun and engaging way from the comfort of their own homes.'}
      />
      <ProjectInfoSection
        title={'Design'}
        content={'The MRI Simulator takes the user through the story of Murray the cat getting an MRI. Guided by a voice narration, the user presses the button on the MRI machine to take an anxious Murray\'s first MRI scan. Murray\'s anxiety causes him to move while inside the scanner, and as a result, the picture comes out blurry. The user is then prompted to pet Murray to calm him down, and then proceeds to repeat the scanning process. Murray\'s ability to stay calm and still in the scanner produces a clear picture. This completes the sequential experience. At this point, users can repeat the guided experience, or they can continue interacting with the MRI by pressing buttons to hear the sounds of the machine again, or sliding the table in and out.'}
      >
        <img src={design} />
      </ProjectInfoSection>
      <ProjectInfoSection
        title={'Prototyping'}
        content={'The early prototype focused on establishing the flow of the experience. The user is able to walk through an MRI procedure, guided by a narrator\'s voice and call to actions, such as buttons in the UI overlay. The MRI machine model carries out basic functionality, such as sliding the table in and out of the machine and flashing lights during the scan.'}
      >
        <img src={prototype} />
      </ProjectInfoSection>
      <ProjectInfoSection
        title={'Final Product'}
        content={'During usability studies, some users felt that the size of the MRI model was too small. In the following iteration, a size slider was added to the UI for users to adjust the size of the model as they saw fit. Facial expressions and sounds were added to Murray to clearly show when he was feeling happy or nervous. Font and colors in the UI were adjusted to match the company branding.'}
      >
        <img src={finalProduct} />
      </ProjectInfoSection>
      <ProjectInfoSection
        content={'In the final product, the MRI machine model was refined to look more realistic, and the call to actions, such as the buttons on the model and in the UI, were made clearer using color and animation.'}
      >
        <img src={demo} />
      </ProjectInfoSection>
    </div>
  );
}
