import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './style.scss';

interface NavItem {
  title: string;
  path: string;
}

const navItems: NavItem[] = [
  {
    title: 'Work',
    path: '/work'
  },
  { title: 'About',
    path: '/about' }];

export default function DrawerAppBar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getButton = (item: NavItem) => (
    <Button
      key={item.title}
      className="nav-item"
      component={NavLink}
      to={item.path}
    >
      {item.title}
    </Button>
  );

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            {getButton(item)}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box className="nav-bar">
      <AppBar component="nav" className="nav-bar__container">
        <Toolbar className="nav-bar__container__content">

          {/* Logo */}
          <Box sx={{ display: { xs: 'flex' } }}>
            <NavLink to="/">
              <img src={logo}/>
            </NavLink>
            <Typography variant="body2" className='title'>
              {'Engineer & Designer'}
            </Typography>
          </Box>

          {/* Navigation - Web */}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => getButton(item))}
          </Box>
          {/* Navigation - Mobile */}
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className='menu-button'
          >
            <MenuIcon htmlColor='#16215c'/>
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer - mobile */}
      <Drawer
        className='drawer'
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor={'top'}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
