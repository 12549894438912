import AWSAmplifyStudio from '../components/project/content/aws-amplify-studio';
import CCSLandingPage from '../components/project/content/umich-ccs-landing-page';
import CCSSitesLocator from '../components/project/content/umich-ccs-locator';
import MRIProcedureSimulator from '../components/project/content/mri-simulator';
import SpaceTransformation from '../components/project/content/space-transformation';
import Wrecked from '../components/project/content/wrecked';

export interface Project {
    title: string;
    description: string;
    overview: string;
    techStack: string;
    slug: string;
    thumbnailExt: string;
    content: () => JSX.Element;
}

export const projects: Project[] = [
  {
    title: 'AWS Amplify Studio',
    description: 'Full-Stack Web Development',
    overview: 'AWS Amplify Studio is a visual development environment that lets developers easily build and ship complete web and mobile apps quickly.',
    techStack: 'Typescript, React, AWS, Figma',
    slug: 'aws-amplify-studio',
    thumbnailExt: 'jpeg',
    content: AWSAmplifyStudio
  },
  {
    title: 'MRI Procedure Simulator',
    description: 'AR Game Design & Development',
    overview: 'An MRI scan is a procedure that can be scary for children if they don\'t know what to expect, such as loud noises and cramped spaces. The MRI Simulator seeks to improve this patient experience by providing them with an engaging way to learn about procedural expectations and develop self-management skills. It uses Augmented Reality to provide interactive, 3D content that more effectively allows patients to visualize and contextualize pertinent information.',
    techStack: 'Unity, C#, iOS, Android, Vuforia, AWS',
    slug: 'mri-simulator',
    thumbnailExt: 'jpg',
    content: MRIProcedureSimulator,
  },
  {
    title: 'Hospital Space Transformation',
    description: 'AR Game Design & Development',
    overview: 'A hospital can be a difficult and stressful environment for pediatric patients. This project uses AR to create positive experiences for both patients and their families during their stay and to motivate them to stay active while they recover.',
    techStack: 'Unity, C#, iOS, Android, Vuforia, AWS',
    slug: 'space-transformation',
    thumbnailExt: 'png',
    content: SpaceTransformation
  },
  {
    title: 'Wrecked',
    description: 'Game Design & Development',
    overview: 'Wrecked is a 2D multiplayer smash-style video game where players operate a jet-powered, wrecking ball toting construction vehicle to take out their opponents while using power ups and anything they destroy to their advantage. The last player standing wins! Developed alongside Nigel Bailie, Jennifer Dailey and Joseph Pohlman as a Capstone project at the University of Michigan.',
    techStack: 'Unity, C#',
    slug: 'wrecked',
    thumbnailExt: 'png',
    content: Wrecked
  },
  {
    title: 'Campus Computing Sites Landing Page',
    description: 'UX Research & Design',
    overview: 'The Campus Computing Sites (CCS) landing page is the default website University of Michigan students are presented with when they log into a campus computer. The goal of this project was to identify the challenges students were facing while using the website and the required improvements to make the website easier to navigate and use.',
    techStack: 'Surveys, Google SDK, HTML/CSS',
    slug: 'umich-ccs-landing-page',
    thumbnailExt: 'png',
    content: CCSLandingPage
  },
  {
    title: 'Campus Computing Sites Locator',
    description: 'UI Design & Development',
    overview: 'The University of Michigan Ann Arbor campus has many computing sites, each offering a variety of services. This website helps students, staff and faculty easily find a campus computing site that fits all their needs.',
    techStack: 'Surveys, Google SDK, HTML/CSS',
    slug: 'umich-ccs-locator',
    thumbnailExt: 'png',
    content: CCSSitesLocator
  },
];
