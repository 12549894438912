import * as React from 'react';
import Typography from '@mui/material/Typography';

import './style.scss';

interface ProjectInfoSectionProps extends React.PropsWithChildren  {
    title?: string;
    subtitle?: string;
    content?: string;
    className?: string;
}

export default function ProjectInfoSection(props: ProjectInfoSectionProps) {

  return (
    <div className={'section ' + (props.className ?? '')}>
      {props.title && (<Typography variant="h4" className="heading center center-text">
        {props.title}
      </Typography>)}
      {props.subtitle && (<Typography variant="h4" className="content__heading center-text">
        {props.subtitle}
      </Typography>)}
      {props.content && (<Typography variant="body2" className="content center">
        {props.content}
      </Typography>)}
      {props.children}
    </div>
  );
}
