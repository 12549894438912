import * as React from 'react';
import ProjectInfoSection from '../ProjectInfoSection';
import dinosaur from '../../../../assets/space-transformation/dinosaur.gif';
import butterflies from '../../../../assets/space-transformation/butterflies.gif';
import phoenix from '../../../../assets/space-transformation/phoenix.gif';

export default function Content() {

  return (
    <div className='section'>
      <ProjectInfoSection
        title={'User Story'}
        content={'When a patient associates being in a hospital with feelings of fear, anxiety and sadness, it lowers their motivation to stay active. Movement is critical in patient recovery and has long been a standard of care for adults. With pediatric patients, fostering movement can be challenging. Pediatric patients need a fun and engaging tool that can motivate them to be active especially after in-patient procedures, and distract them during medical procedures thereby reducing their pain perception.'}
      />
      <ProjectInfoSection
        title={'Design & Implementation'}
        subtitle={'Magic Portal Game'}
        content={'Using AR and a visual target, a child life specialist places the target on any wall and the patient can "open" a portal to an underwater game using the SpellBound app. The experience starts by introducing the patient to the mechanics of the game and once the tutorial stage is complete, the underwater world is revealed and gameplay starts.'}
      >
        <iframe
          className='video'
          src="https://www.youtube.com/embed/XIpTjz2PGC0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
        </iframe>
      </ProjectInfoSection>
      <ProjectInfoSection
        subtitle={'Interactive Mural'}
        content={'Using various parts of an existing mural as targets, patients can explore all parts of the mural by using the SpellBound app to reveal the interactive AR experiences with the encouragement and support of their child life specialist and their families.'}
      >
        <img src={dinosaur} />
      </ProjectInfoSection>
      <ProjectInfoSection
        subtitle={'Quick Distract Cards'}
        content={'This tool supplements hospitals\' distraction therapy techniques with engaging AR experiences that patients can interact with while getting medical procedures such as IV starts, blood draws and catheterizations. The visual targets are small cards that child life specialists can carry around with them and easily use with the SpellBound app at any time to help a patient.'}
      >
        <div className='multi-img'>
          <img src={butterflies} />
          <img src={phoenix} />
        </div>
      </ProjectInfoSection>
    </div>
  );
}
