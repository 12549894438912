import React from 'react';
import NavBar from './components/navigation';

import './App.scss'
import { Container, createTheme, ThemeProvider } from '@mui/material';

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import About from './components/about';
import Footer from './components/footer';
import ProjectInfo from './components/project/content/ProjectInfo';
import { Project, projects } from './data/projects';
import Home from './components/project/ProjectCollection';
import PageNotFound from './components/404';

function App() {

  const typographyOverrides = {
    styleOverrides: {
      root: {
        fontFamily: 'Montserrat, sans-serif;'
      },
    }
  }

  const theme = createTheme({
    components: {
      MuiTypography: typographyOverrides,
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            '@media (min-width: 600px)': {
              paddingLeft: 0,
              paddingRight: 0,
            }
          }
        }
      },
      MuiButton: typographyOverrides,
      MuiMenuItem: typographyOverrides,
      MuiInputBase: typographyOverrides
    },
  });


  return (
    <BrowserRouter>
      <ThemeProvider theme={theme} >
        <Container className="container">
          <NavBar />
          <Container className="container__projects">
            <Routes>
              <Route path="/" element={<Navigate to="work"/>}/>
              <Route path="work" element={<Home />}/>
              <Route path="about" element={<About/>} />
              {projects.map((p: Project) => {
                return (<Route path={p.slug} element={<ProjectInfo project={p}/>} />)
              })}
              <Route path="404" element={<PageNotFound />} />
              <Route path="*" element={<Navigate to="404"/>} />
            </Routes>
          </Container>
          <Footer/>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
