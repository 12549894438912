import { Article, Email, GitHub, LinkedIn } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import profile from '../../assets/profile.png';
import { S3_PREFIX } from '../../data/constants';

import './style.scss';

export default function About() {

  return (
    <div className="about">
      <div className="about__grid">
        <img src={profile} />
        <Box sx={{ display: 'grid' }}>
          <Typography variant="h2" className='title'>
            {'Hey there, I\'m Adilah'}
          </Typography>
          <Typography variant="body2" className='paragraph'>
            {'I\'m a software engineer for Amazon Web Services (AWS) based in Pittsburgh, PA. During my time as an Amazonian, I have worked on AWS Amplify Studio and Buyer-Seller Messaging. I\'m passionate about creating memorable experiences for users through thoughtful and innovative design.'}
          </Typography>
          <div className="about__grid__buttons">
            <Button
              variant="outlined"
              href={`${S3_PREFIX}/resume.pdf`}
              target="_blank"
              startIcon={<Article />}
            >
              RESUME
            </Button>
            <Button
              variant="outlined"
              href="mailto:kuadilah@umich.edu?subject=Hello!"
              startIcon={<Email />}
            >
              EMAIL
            </Button>
            <Button
              variant="outlined"
              href="https://www.linkedin.com/in/kuadilah/"
              target="_blank"
              startIcon={<LinkedIn />}
            >
              LINKEDIN
            </Button>
            <Button
              variant="outlined"
              href="https://github.com/adilahkb"
              target="_blank"
              startIcon={<GitHub />}
            >
              GITHUB
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
}
