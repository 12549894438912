import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { Project } from '../../../data/projects';

import './style.scss';
import { NavLink } from 'react-router-dom';

interface ProjectInfoProps {
  project: Project;
}

export default function ProjectInfo(props: ProjectInfoProps) {

  return (
    <>
      <Box className='section' sx={ { mt: 0 }}>
        <Button component={NavLink} to="/" className="button">
          <Typography variant="body2">
            {'< Back'}
          </Typography>
        </Button>
        <Typography variant="h3" className="project-title">
          {props.project.title}
        </Typography>
        <Typography variant="body2" className="project-subtitle">
          {props.project.description}
        </Typography>
        <div className='overview'>
          <Typography variant="h4" className="heading">
                Overview
          </Typography>
          <Typography variant="body2" className="content">
            {props.project.overview}
          </Typography>
        </div>
        <div>
          <Typography variant="h4" className="heading">
                Tech Stack
          </Typography>
          <Typography variant="body2" className="content center-text">
            {props.project.techStack}
          </Typography>
        </div>
      </Box>
      {props.project.content()}
    </>
  );
}
